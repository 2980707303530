import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { isSpeechRecognizeState } from "../../store/selectors/speech_recognize";
import SpeechRecognize from "../../models/SpeechRecognize";
import {
  onresultFunctionGet,
  onaudioendFunctionGet,
} from "../../common/speech_recognition_common";
import { speechRecognizeState } from "../../store/atoms/speech_recognize";
import { speechTextState } from "../../store/atoms/speech_text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons/faEllipsisV";
import { FormControlLabel, Switch } from "@mui/material";
import styled from "@emotion/styled";
import { MOB_MAX_WIDTH } from "../../common/constants";
import { interpreteLangState } from "store/atoms/interprete_lang";

function DiktafontMainHeader({ diktafontNavigationActToggle }) {
  const isSpeechRecognize = useRecoilValue(isSpeechRecognizeState);
  const interpreteLang = useRecoilValue(interpreteLangState);
  const [speechRecognize, setSpeechRecognize] =
    useRecoilState(speechRecognizeState);
  const setSpeechText = useSetRecoilState(speechTextState);
  const [switchLabel, setSwitchLabel] = useState("");
  const [recognizeStart, setRecognizeStart] = useState(false);
  useEffect(() => {
    const recognSwitchLabel = !isSpeechRecognize ? "START" : "STOP";
    setSwitchLabel(recognSwitchLabel);
    if (recognizeStart && !isSpeechRecognize) {
      setTimeout(() => {
        speechRecognizeStart();
      }, 500);
    }
  }, [recognizeStart, isSpeechRecognize]);

  const speechRecognizeStart = () => {
    const onresult = onresultFunctionGet(setSpeechText);
    const onaudioend = onaudioendFunctionGet(setSpeechRecognize);
    const lang = interpreteLang === "sr" ? "sr-RS" : "en";
    const speechRecogn = new SpeechRecognize({ onresult, onaudioend, lang });
    setSpeechRecognize(speechRecogn);
  };

  const speechRecognizeStop = () => {
    if (speechRecognize !== null) {
      speechRecognize.stopRecognition();
    }
    setSpeechRecognize(null);
  };

  const actionSwitchChangeHandler = (event) => {
    const recognitionActive = event.target.checked;
    if (!recognitionActive) {
      speechRecognizeStop();
    } else {
      speechRecognizeStart();
    }
  };

  const recognizeClickHandle = () => {
    console.log("recognizeClickHandle");
    if (recognizeStart) {
      speechRecognizeStop();
    }
    setRecognizeStart(!recognizeStart);
  };

  return (
    <DivMainHeader>
      <div>
        <Button
          variant="outlined"
          onClick={recognizeClickHandle}
          sx={{ minWidth: "2rem", marginRight: "2rem", fontSize: "1.2rem" }}
        >
          {!recognizeStart ? "START" : "PAUSE"}
        </Button>

        <FormControlLabel
          control={
            <Switch
              onChange={actionSwitchChangeHandler}
              checked={isSpeechRecognize}
            />
          }
          // label={switchLabel}
        />
      </div>
      <DivMainMenuNav>
        <IconButton aria-label="Example" onClick={diktafontNavigationActToggle}>
          <FontAwesomeIcon icon={faEllipsisV} />
        </IconButton>
      </DivMainMenuNav>
    </DivMainHeader>
  );
}

const DivMainMenuNav = styled.div`
  display: none;
  @media (max-width: ${MOB_MAX_WIDTH}) {
    display: inline;
  }
`;

const DivMainHeader = styled.div`
  display: flex;
  justify-content: right;
  align-items: baseline;
`;

export default DiktafontMainHeader;
