import { Button } from "@mui/material";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useRecoilState } from "recoil";
import { workCollectionDelete } from "service/work_collection/work_collection_common";
import { workCollectionsState } from "store/atoms/work_collections";
import styled from "styled-components";
import { WorkCollection } from "type/work_collection";
import WorkCollectionItemEdit from "./WorkCollectionItemEdit";

type Props = {
  index: number;
  workCollection: WorkCollection;
  afterDelete?: Function;
};

const WorkCollectionItem = ({ index, workCollection, afterDelete }: Props) => {
  const [workCollections, setWorkCollections] =
    useRecoilState(workCollectionsState);
  const [editWorkCollection, setEditWorkCollection] = useState<boolean>(false);
  const [workCollectionItem, setWorkCollectionItem] =
    useState<WorkCollection>(workCollection);
  const rowStart: number = Math.trunc(index / 5) + 1;
  const rowEnd: number = rowStart + 1;
  const handleEditClick = () => {
    setEditWorkCollection(true);
  };
  const handleDeleteClick = async () => {
    if (workCollectionItem.id) {
      await workCollectionDelete(workCollectionItem.id);
      toast.success("Work Collection deleted");
      if (afterDelete) {
        afterDelete(workCollectionItem);
      }
    }
  };
  const afterUpdate = (workCollectionData: WorkCollection) => {
    console.log("afterUpdate - workCollectionData", workCollectionData);
    setWorkCollectionItem(workCollectionData);
    const workCollectionUpd: WorkCollection[] = [...workCollections];
    workCollectionUpd[index] = workCollectionData;
    setEditWorkCollection(false);
  };

  return (
    <Div
      style={{
        gridArea: `${rowStart} / ${(index % 5) + 1} / ${rowEnd} / ${
          (index % 5) + 2
        }`,
      }}
    >
      {!editWorkCollection && <div>{workCollectionItem.name}</div>}
      {editWorkCollection && (
        <WorkCollectionItemEdit
          workCollection={workCollectionItem}
          onCancelClick={() => setEditWorkCollection(false)}
          afterUpdate={afterUpdate}
        />
      )}

      {!editWorkCollection && <div>Info work count</div>}
      <DivButtons>
        {!editWorkCollection && (
          <Button onClick={handleEditClick} variant="outlined">
            Edit
          </Button>
        )}
        <Button onClick={handleDeleteClick} variant="outlined">
          Delete
        </Button>
      </DivButtons>
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  cursor: pointer;
  color: #764141;
  text-decoration: none;
`;

const DivButtons = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-direction: row;
  justify-content: space-between;
`;

export default WorkCollectionItem;
