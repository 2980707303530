import React from "react";
import { useRecoilValue } from "recoil";
import { interpreteActiveState } from "store/atoms/interprete_active";
import styled from "styled-components";
import { ImageDescription } from "type/interprete";

const InterpreteWords = () => {
  const interpreteActive = useRecoilValue(interpreteActiveState);

  // console.log(
  //   "interpreteActive.interprete.length",
  //   interpreteActive.interprete.length
  // );

  const interpreteWords = interpreteActive.interprete.map(
    (imageDescription: ImageDescription, index: number) => (
      <InterpreteWord key={`word_${index}`}>
        {imageDescription.text}
      </InterpreteWord>
    )
  );

  return <Div>{interpreteWords.reverse()}</Div>;
};

const Div = styled.div`
  display: flex;
  margin-top: 2rem;
  flex-direction: column;
`;

const InterpreteWord = styled.div`
  text-align: center;
  font-size: 1.5rem;
  margin-top: 0.5rem;
  text-align: right;
`;

export default InterpreteWords;
