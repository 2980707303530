export function randInRangeGet(minVal, maxVal) {
  // get random integer element in range [minVal, maxVal]
  let randVal = Math.random();
  randVal = minVal + (maxVal - minVal) * randVal;
  randVal = Math.floor(randVal);
  return randVal;
}

export function randFloat01InRangeGet(minVal, maxVal) {
  // get random float in range [minVal, maxVal] where [minVal, maxVal] is subrange of [0, 1]
  // minVal or maxVal should be >= 0.0001
  const tenDg = 100000;
  const minValInt = parseInt(minVal * tenDg);
  const maxValInt = parseInt(maxVal * tenDg);
  let randVal = randInRangeGet(minValInt, maxValInt);
  // console.info(randVal);
  randVal = randVal / tenDg;
  // console.info(randVal);
  // randVal = Math.round(randVal, 4)
  return randVal;
}

export function shuffleArray(arr) {
  return arr.sort(function () {
    return Math.random() - 0.5;
  });
}
