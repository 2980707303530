import { Button, Paper, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputField from "components/UI/InputField";
import { workAdd, workGet } from "service/work/work_common";
import toast from "react-hot-toast";
import { WorkEditSchema } from "validation/schema/work_edit.schema";
import { Work } from "type/work";
import { workDefaultGet } from "util/work_common";

const WorkComponent = () => {
  const { workId } = useParams();
  const [textValue, setTextValue] = useState<string>("");
  const [work, setWork] = useState<Work>(workDefaultGet());
  const { handleSubmit, reset, control, setValue, getValues } = useForm({});
  useEffect(() => {
    if (workId) {
      workGet(workId).then((workItem: Work) => {
        console.log("workItem", workItem);
        setWork(workItem);
      });
    }
  }, [workId]);
  useEffect(() => {
    setValue("name", work.name);
    console.log("getValues", getValues());
  }, [work]);

  const onTextChange = (e: any) => setTextValue(e.target.value);
  const onSubmit = async (data: any) => {
    console.log(data);
    // const workId: string = await workAdd(data.name, "author 1");
    // console.log("added", workId);
    toast.success("Work Adding removed");
  };
  const handleReset = () => setTextValue("");

  console.log("work.name", work.name);

  // const name: string = workId ?

  return (
    <Div>
      <WorksLink to="/works">Works</WorksLink>
      <DivWork>
        <form>
          <InputField
            name="name"
            control={control}
            label="Name"
            defaultValue={work.name}
          />
          <Button onClick={handleSubmit(onSubmit)}>Submit</Button>
          <Button onClick={() => reset()} variant={"outlined"}>
            Reset
          </Button>
        </form>
      </DivWork>
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  margin-left: 5%;
  margin-top: 2rem;
  width: 90%;
  flex-direction: column;
  color: #764141;
`;

const WorksLink = styled(Link)`
  margin-top: 2rem;
  font-size: 1.5rem;
  cursor: pointer;
  color: #764141;
  text-decoration: none;
`;

const DivWork = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 2rem;
  row-gap: 2rem;
  margin-top: 2rem;
`;

export default WorkComponent;
