import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

type Props = {
  index: number;
};

const WorkInterpretationItem = ({ index }: Props) => {
  const rowStart: number = Math.trunc(index / 5) + 1;
  const rowEnd: number = rowStart + 1;
  return (
    <InterpretationLink
      to="/work-interprete/new"
      style={{
        gridArea: `${rowStart} / ${(index % 5) + 1} / ${rowEnd} / ${
          (index % 5) + 2
        }`,
      }}
    >
      <div>Info work</div>
      <Img src="/images/work_example.jpg" />
      <div>Info interpretation</div>
    </InterpretationLink>
  );
};

const Div = styled.div``;

const InterpretationLink = styled(Link)`
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  cursor: pointer;
  color: #764141;
  text-decoration: none;
`;

const Img = styled.img`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 100%;
`;

export default WorkInterpretationItem;
