import { Button } from "@mui/material";
import WorkCollectionSelect from "components/work_collection/WorkCollectionSelect";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { worksGet } from "service/work/work_common";
import styled from "styled-components";
import { Work } from "type/work";
import { workDefaultGet } from "util/work_common";
import WorkItem from "./WorkItem";
import WorkItemEdit from "./WorkItemEdit";

const WorkList = () => {
  const { control } = useForm({});
  const [works, setWorks] = useState<Work[]>([]);
  const [addWork, setAddWork] = useState<boolean>(false);
  const [filterWorkCollection, setFilterWorkCollection] = useState<string>("");

  useEffect(() => {
    worksGet().then((workList: Work[]) => {
      console.log(workList);
      let workItems: Work[] = [...workList];
      if (filterWorkCollection !== "") {
        workItems = workItems.filter(
          (workItem: Work) => workItem.workCollection === filterWorkCollection
        );
      }
      setWorks(workItems);
    });
  }, [filterWorkCollection]);

  const afterDelete = (workItem: Work) => {
    const ind: number = works.findIndex(
      (work: Work) => work.id === workItem.id
    );
    if (ind >= 0) {
      const worksUpd: Work[] = [...works];
      worksUpd.splice(ind, 1);
      setWorks(worksUpd);
    }
  };

  const items: JSX.Element[] = works.map((work: Work, i) => {
    return (
      <WorkItem index={i} key={work.id} work={work} afterDelete={afterDelete} />
    );
  });

  const addClickHandle = () => {
    console.log("addClickHandle");
    setAddWork(true);
  };

  const afterAdd = (work: Work) => {
    setWorks([...works, work]);
    setAddWork(false);
  };

  const workCollectionSelect = (workCollectionId: string) => {
    console.log("workCollectionSelect - workCollectionId", workCollectionId);
    setFilterWorkCollection(workCollectionId);
  };

  return (
    <Div>
      <DivFilter>
        <WorkCollectionSelect
          name="filterWorkCollection"
          control={control}
          label="Work Collection"
          onSelect={workCollectionSelect}
          includeEmpty={true}
        />
      </DivFilter>
      {!addWork && (
        <ButtonAdd variant="outlined" color="inherit" onClick={addClickHandle}>
          Add Work
        </ButtonAdd>
      )}
      {addWork && (
        <WorkItemEdit
          work={workDefaultGet()}
          onCancelClick={() => setAddWork(false)}
          afterAdd={afterAdd}
        />
      )}
      {/* <HomeLink to="/work/new">Add Work Collection</HomeLink> */}
      <DivList>{items}</DivList>
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  margin-left: 5%;
  margin-top: 2rem;
  width: 90%;
  flex-direction: column;
  color: #764141;
`;

const DivFilter = styled.div`
  width: 25%;
  margin-bottom: 2rem;
  div {
    width: 100%;
  }
`;

const ButtonAdd = styled(Button)`
  margin-top: 2rem;
  width: 10%;
`;

const DivList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 6rem;
  row-gap: 2rem;
  margin-top: 2rem;
`;

export default WorkList;
