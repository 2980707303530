import { atom } from "recoil";
import { Interprete } from "type/interprete";
import { interpreteDefaultGet } from "util/interprete_common";

const defaultValue: Interprete = interpreteDefaultGet();

export const interpreteActiveState = atom({
  key: "interpreteActive",
  default: defaultValue,
});
