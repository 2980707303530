/* eslint-disable react/prop-types */
import InterpreteSave from "components/interprete/InterpreteSave";
import InterpreteSearch from "components/interprete/InterpreteSearch";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { imageDescriptionSearch } from "service/image/image";
import { keydownActionGet } from "service/interprete/interprete";
import { workGet } from "service/work/work_common";
import { interpreteActiveState } from "store/atoms/interprete_active";
import { interpreteLangState } from "store/atoms/interprete_lang";
import { workActiveState } from "store/atoms/work_active";
import styled from "styled-components";
import { ImageDescription, Interprete } from "type/interprete";
import { Work } from "type/work";
import {
  imageDescriptionDefaultGet,
  interpreteDefaultGet,
} from "util/interprete_common";
import InterpreteImages from "./InterpreteImages";
import WorkImageWords from "./WorkImageWords";

const WorkInterprete = () => {
  const [searchParams] = useSearchParams();
  const { interpreteId } = useParams();
  const [workActive, setWorkActive] = useRecoilState(workActiveState);
  const interpreteLang = useRecoilValue(interpreteLangState);
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [interpreteActive, setInterpreteActive] = useRecoilState(
    interpreteActiveState
  );
  useEffect(() => {
    if (workActive.id) {
      const interprete: Interprete = interpreteDefaultGet(workActive.id);
      setInterpreteActive(interprete);
    }
  }, [workActive]);

  document.addEventListener("keydown", function (event) {
    const action: string = keydownActionGet(event);
    // console.log(
    //   `Key: ${event.key} with keycode ${event.keyCode} has been pressed, action: ${action}`
    // );
    if (action === "search") {
      setSearchOpen(true);
    }
  });

  const searchActivate = async (searchText: string) => {
    const interprete: Interprete = JSON.parse(JSON.stringify(interpreteActive));
    console.log("interprete", interprete);

    const imageDescription: ImageDescription = await imageDescriptionSearch(
      searchText,
      interpreteLang
    );
    interprete.interprete.push(imageDescription);
    setInterpreteActive(interprete);
    console.log("searchActivate", searchText);
  };

  const workId = searchParams.get("work");
  console.log("workId", workId);
  useEffect(() => {
    if (workActive.id !== workId && workId) {
      workGet(workId).then((work: Work) => {
        setWorkActive(work);
      });
    }
  }, [workId]);

  console.log("interpreteId", interpreteId);
  return (
    <Div>
      <InterpreteSearch
        searchOpen={searchOpen}
        setSearchOpen={setSearchOpen}
        searchActivate={searchActivate}
      />
      <InterpreteImages />
      <WorkImageWords />
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

export default WorkInterprete;
