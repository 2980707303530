/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { workActiveState } from "store/atoms/work_active";
import styled from "styled-components";
import { Work } from "type/work";
import { workDefaultGet } from "util/work_common";
import WorkImage from "./WorkImage";

const WorkNavigation = () => {
  const navigate = useNavigate();
  const [workInterprete, setWorkInterprete] = useState<Work>(workDefaultGet());
  const workActive = useRecoilValue(workActiveState);

  const author: string = "Author";
  const work: string = "Work";

  const interpretationNewNavigate = () => {
    console.log("interpretationNewNavigate");
    const workId: string | null = workInterprete.id
      ? workInterprete.id
      : workActive.id;
    if (workId) {
      navigate(`/work-interprete/new?work=${workId}`);
    }
  };

  return (
    <Div>
      <WorkImage />
      {/* <DivStartNav onClick={interpretationNewNavigate}>START</DivStartNav> */}
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 45%;
  margin-right: 5%;
`;

const DivStartNav = styled.div`
  position: fixed;
  bottom: 5rem;
  right: 5%;
  font-size: 3rem;
  cursor: pointer;
  color: #764141;
`;

export default WorkNavigation;
