import { atom } from "recoil";
import { Work } from "type/work";
import { workDefaultGet } from "util/work_common";

const defaultValue: Work = workDefaultGet();

export const workActiveState = atom({
  key: "workActive",
  default: defaultValue,
});
