// import axios from "util/api/api_common";
import axios from "axios";
import { apiUrlGet } from "common/common";
import { Work } from "type/work";
import { workDefaultGet } from "util/work_common";

export async function workAdd(
  name: string,
  author: string,
  year: string,
  imagePath: string = "",
  fileName: string = "",
  workCollectionId: string = "",
  licence: string = ""
): Promise<string> {
  let workId: string = "";
  const workUrl = apiUrlGet("work");
  const response = await axios.post(workUrl, {
    name,
    author,
    year,
    imagePath,
    fileName,
    workCollection: workCollectionId,
    licence,
  });
  workId = response.data;

  return workId;
}

export async function worksGet(options: Object = {}): Promise<Work[]> {
  let queryPart = "";
  if ("workCollection" in options) {
    queryPart +=
      (queryPart !== "" ? "&" : "") +
      "workCollection=" +
      options.workCollection;
  }
  if (queryPart) {
    queryPart = "?" + queryPart;
  }

  let works: Work[] = [];
  const workUrl = apiUrlGet("work" + queryPart);
  const response = await axios.get(workUrl);
  console.log("worksGet - workUrl", workUrl);
  console.log("worksGet - response.data", response.data);

  works = response.data.map((workItem: Work) => {
    const work: Work = workDefaultGet();
    work.id = workItem.id;
    work.name = workItem.name;
    work.author = workItem.author;
    work.fileName = workItem.fileName;
    work.imagePath = workItem.imagePath;
    work.year = workItem.year;
    work.workCollection = workItem.workCollection;
    work.licence = workItem.licence;

    return work;
  });

  return works;
}

export async function workGet(workId: string): Promise<Work> {
  let work: Work = workDefaultGet();
  const workUrl = apiUrlGet(`work/${workId}`);
  const response = await axios.get(workUrl);
  const workItem: Work = response.data;
  work.id = workItem.id;
  work.name = workItem.name;
  work.author = workItem.author;
  work.fileName = workItem.fileName;
  work.imagePath = workItem.imagePath;
  work.year = workItem.year;
  work.workCollection = workItem.workCollection;
  work.licence = workItem.licence;

  return work;
}

export async function workUpdate(
  id: string,
  name: string,
  author: string,
  year: string,
  imagePath: string = "",
  fileName: string = "",
  workCollectionId: string = "",
  licence: string = ""
): Promise<string> {
  const workUrl = apiUrlGet(`work/${id}`);
  await axios.put(workUrl, {
    name,
    author,
    year,
    imagePath,
    fileName,
    workCollection: workCollectionId,
    licence,
  });

  return id;
}

export async function workDelete(id: string): Promise<string> {
  const workUrl = apiUrlGet(`work/${id}`);
  await axios.delete(workUrl);

  return id;
}
