import { ImageDescription, ImagePos, Interprete } from "type/interprete";
import { Work } from "type/work";

export function interpreteDefaultGet(workId: string = ""): Interprete {
  return {
    id: null,
    workId,
    createdAt: new Date(),
    interpreteAuthor: "",
    interpreteName: "",
    interprete: [],
  };
}

export function imageDescriptionDefaultGet(
  text: string = ""
): ImageDescription {
  return {
    text,
    createdAt: new Date(),
    images: [],
  };
}

export function interpreteImagePosGet(
  imageDescriptions: ImageDescription[]
): ImagePos[] {
  const imagePositions: ImagePos[] = [];
  for (let i = 0; i < imageDescriptions.length; i++) {
    for (let j = 0; j < imageDescriptions[i].images.length; j++) {
      imagePositions.push(imageDescriptions[i].images[j]);
    }
  }
  return imagePositions;
}
