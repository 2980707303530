/* eslint-disable react/prop-types */
import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import WorkInterpreteInfo from "./WorkInterpreteInfo";
import InterpreteWords from "./InterpreteWords";
import DiktafontMainHeader from "components/layout/DiktafontMainHeader";
import { toast } from "react-hot-toast";
import { useRecoilState } from "recoil";
import { interpreteActiveState } from "store/atoms/interprete_active";
import { interpreteDefaultGet } from "util/interprete_common";
import { interpreteAdd } from "service/interprete/interprete";
import InterpreteSave from "components/interprete/InterpreteSave";
import { Interprete } from "type/interprete";

const WorkImageWords = () => {
  const [interpreteActive, setInterpreteActive] = useRecoilState(
    interpreteActiveState
  );
  // const [saveOpen, setSaveOpen] = useState<boolean>(false);

  // const navigate = useNavigate();

  // const saveActivate = async (
  //   interpreteName: string,
  //   interpreteAuthor: string
  // ) => {
  //   setSaveOpen(false);
  //   const interprete: Interprete = JSON.parse(JSON.stringify(interpreteActive));
  //   interprete.interpreteName = interpreteName;
  //   interprete.interpreteAuthor = interpreteAuthor;
  //   const interpreteId: string = await interpreteAdd(interprete);
  //   toast.success("Interpretation completed");
  //   console.log("interpretation added", interpreteId);

  //   setInterpreteActive(interpreteDefaultGet());

  //   navigate("/");
  // };

  // const interpretationCompleteHandle = async () => {
  //   setSaveOpen(true);
  // };

  return (
    <Div>
      {/* <InterpreteSave
        saveOpen={saveOpen}
        setSaveOpen={setSaveOpen}
        saveActivate={saveActivate}
      /> */}

      <WorkInterpreteInfo />
      <InterpreteWords />
      {/* <DivCompleteNav onClick={interpretationCompleteHandle}>
        COMPLETE
      </DivCompleteNav> */}
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  // justify-content: center;
  width: 45%;
  margin-right: 5%;
`;

const DivCompleteNav = styled.div`
  position: fixed;
  bottom: 5rem;
  right: 5%;
  // margin-top: 2rem;
  font-size: 3rem;
  cursor: pointer;
  color: #764141;
  text-align: right;
`;

export default WorkImageWords;
