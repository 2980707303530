import { KeyboardEventHandler, useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import styled from "styled-components";
import { Input } from "@mui/material";
import InputField from "components/UI/InputField";
import { useForm } from "react-hook-form";

type Props = {
  saveOpen: boolean;
  setSaveOpen: Function;
  saveActivate: Function;
};

const InterpreteSave = ({ saveOpen, setSaveOpen, saveActivate }: Props) => {
  const { handleSubmit, reset, control, setValue, getValues } = useForm({});

  const handleClose = () => {
    setSaveOpen(false);
  };

  const handleOk = () => {
    saveActivate();
  };

  const onSubmit = async (data: any) => {
    console.log(data);
    const interpreteName: string = data.interpreteName;
    const interpreteAuthor: string = data.interpreteAuthor;
    saveActivate(interpreteName, interpreteAuthor);

    // if (!workCollection.id) {
    //   const workCollectionId: string = await workCollectionAdd(data.name);
    //   console.log("added", workCollectionId);
    //   toast.success("Work Collection added");
    //   if (afterAdd) {
    //     afterAdd({
    //       name: data.name,
    //       id: workCollectionId,
    //     });
    //   }
    // } else {
    //   console.log("update");
    //   await workCollectionUpdate(workCollection.id, data.name);
    //   toast.success("Work Collection updated");
    //   if (afterUpdate) {
    //     afterUpdate({
    //       name: data.name,
    //       id: workCollection.id,
    //     });
    //   }
    // }
  };

  //   const textSearchActivate = () => {
  //     const searchText: string = textRef.current ? textRef.current.value : "";
  //     searchActivate(searchText);
  //     setSearchOpen(false);
  //   };

  return (
    <div>
      <Dialog open={saveOpen} onClose={handleClose}>
        <DialogTitle>Save Interpretation</DialogTitle>
        <DialogContent>
          <Form>
            <InputField
              name="interpreteName"
              control={control}
              label="Interpretation Name"
            />
            <DivField>
              <InputField
                name="interpreteAuthor"
                control={control}
                label="Interpretation Author"
              />
            </DivField>
            <DivButtons>
              <Button onClick={handleSubmit(onSubmit)} variant="outlined">
                Save
              </Button>
              <Button onClick={handleClose} variant="outlined">
                Cancel
              </Button>
            </DivButtons>
          </Form>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
};

const Form = styled.form`
  display: flex;
  margin-left: 0%;
  margin-top: 0rem;
  width: 100%;
  flex-direction: column;
  color: #764141;
`;

const DivButtons = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-direction: row;
  justify-content: space-between;
`;

const DivField = styled.div`
  margin-top: 1rem;
`;

export default InterpreteSave;
