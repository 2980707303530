import { KeyboardEventHandler, useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import styled from "styled-components";
import { Input } from "@mui/material";

type Props = {
  searchOpen: boolean;
  setSearchOpen: Function;
  searchActivate: Function;
};

const InterpreteSearch = ({
  searchOpen,
  setSearchOpen,
  searchActivate,
}: Props) => {
  const textRef = useRef<HTMLInputElement>(null);

  const handleClose = () => {
    setSearchOpen(false);
  };

  const handleOk = () => {
    textSearchActivate();
  };

  const textSearchActivate = () => {
    const searchText: string = textRef.current ? textRef.current.value : "";
    searchActivate(searchText);
    setSearchOpen(false);
  };

  const handleKeydown = (ev: any) => {
    const key = ev.key;
    if (key === "Enter") {
      textSearchActivate();
    }
  };

  return (
    <div>
      <Dialog open={searchOpen} onClose={handleClose}>
        <DialogTitle>Search</DialogTitle>
        <DialogContent>
          {/* <Input type="text" ref={textRef} /> */}
          <SearchInput
            type="text"
            ref={textRef}
            autoFocus
            onKeyDown={handleKeydown}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleOk}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const SearchInput = styled.input`
  font-size: 2rem;
  border: 0;
  border-bottom: 0.1rem solid black;
  padding-bottom: 0.3rem;
  &:focus {
    outline: none;
  }
`;

export default InterpreteSearch;
