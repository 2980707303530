import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputField from "components/UI/InputField";
import { workAdd, workGet, workUpdate } from "service/work/work_common";
import toast from "react-hot-toast";
import { WorkEditSchema } from "validation/schema/work_edit.schema";
import { Work } from "type/work";
import WorkCollectionSelect from "components/work_collection/WorkCollectionSelect";
import { useRecoilState, useRecoilValue } from "recoil";
import { workCollectionActiveState } from "store/atoms/work_collection_active";

type Props = {
  work: Work;
  onCancelClick?: Function;
  afterAdd?: Function;
  afterUpdate?: Function;
};

const WorkItemEdit = ({
  work,
  onCancelClick,
  afterAdd,
  afterUpdate,
}: Props) => {
  const workCollectionActive = useRecoilValue(workCollectionActiveState);
  const { handleSubmit, reset, control, setValue, getValues } = useForm({});
  useEffect(() => {
    setValue("name", work.name);
    setValue("author", work.author);
    setValue("fileName", work.fileName);
    setValue(
      "workCollection",
      work.id ? work.workCollection : workCollectionActive.id
    );
    setValue("imagePath", work.imagePath);
    setValue("year", work.year);
    setValue("licence", work.licence);
  }, []);

  const onSubmit = async (data: any) => {
    console.log(data);
    if (!work.id) {
      const workId: string = await workAdd(
        data.name,
        data.author,
        data.year,
        data.imagePath,
        data.fileName,
        data.workCollection,
        data.licence
      );
      console.log("added", workId);
      toast.success("Work added");
      if (afterAdd) {
        afterAdd({
          id: workId,
          name: data.name,
          author: data.author,
          year: data.year,
          imagePath: data.imagePath,
          fileName: data.fileName,
          workCollection: data.workCollection,
          licence: data.licence,
        });
      }
    } else {
      console.log("update");
      await workUpdate(
        work.id,
        data.name,
        data.author,
        data.year,
        data.imagePath,
        data.fileName,
        data.workCollection,
        data.licence
      );
      toast.success("Work updated");
      if (afterUpdate) {
        afterUpdate({
          id: work.id,
          name: data.name,
          author: data.author,
          year: data.year,
          imagePath: data.imagePath,
          fileName: data.fileName,
          workCollection: data.workCollection,
          licence: data.licence,
        });
      }
    }
  };

  const handleCancelClick = () => {
    if (onCancelClick) {
      onCancelClick();
    }
  };

  return (
    <Div>
      <DivWork>
        <Form>
          <InputField
            name="name"
            control={control}
            label="Name"
            defaultValue={work.name}
          />
          <DivField>
            <InputField
              name="author"
              control={control}
              label="Author"
              defaultValue={work.author}
            />{" "}
          </DivField>

          <DivField>
            <InputField
              name="year"
              control={control}
              label="Year"
              defaultValue={work.year}
            />
          </DivField>

          <DivField>
            <InputField
              name="imagePath"
              control={control}
              label="Image Path"
              defaultValue={work.imagePath}
            />
          </DivField>
          <DivField>
            <WorkCollectionSelect
              name="workCollection"
              control={control}
              label="Work Collection"
              defaultValue={
                work.id ? work.workCollection : workCollectionActive.id
              }
            />
          </DivField>

          <DivField>
            <InputField
              name="licence"
              control={control}
              label="Licence"
              defaultValue={work.licence}
            />
          </DivField>

          <DivButtons>
            <Button onClick={handleSubmit(onSubmit)} variant="outlined">
              Submit
            </Button>
            <Button onClick={handleCancelClick} variant="outlined">
              Cancel
            </Button>
          </DivButtons>
        </Form>
      </DivWork>
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  margin-left: 0%;
  margin-top: 0rem;
  width: 100%;
  flex-direction: row;
  color: #764141;
`;

const Form = styled.form`
  display: flex;
  margin-left: 0%;
  margin-top: 0rem;
  width: 100%;
  flex-direction: column;
  color: #764141;
`;

const DivWork = styled.div`
  margin-top: 0rem;
`;

const DivButtons = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-direction: row;
  justify-content: space-between;
`;

const DivField = styled.div`
  margin-top: 1rem;
  width: 100%;
  div {
    width: 100%;
  }
`;

export default WorkItemEdit;
