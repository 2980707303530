import { Work } from "type/work";

export function workDefaultGet(): Work {
  return {
    id: null,
    name: "",
    author: "",
    fileName: "",
    workCollection: "",
    imagePath: "",
    year: "",
    licence: "",
  };
}
