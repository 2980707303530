import { Button } from "@mui/material";
import WorkCollectionSelect from "components/work_collection/WorkCollectionSelect";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { interpretationsGet } from "service/interprete/interprete";
import { worksGet } from "service/work/work_common";
import styled from "styled-components";
import { Interprete } from "type/interprete";
import { Work } from "type/work";
import { workDefaultGet } from "util/work_common";
import InterpreteItem from "./InterpreteItem";

const InterpreteList = () => {
  const { control } = useForm({});
  const [interpretations, setInterpretations] = useState<Interprete[]>([]);
  // const [filterWorkCollection, setFilterWorkCollection] = useState<string>("");

  useEffect(() => {
    interpretationsGet().then((interpreteList: Interprete[]) => {
      console.log(interpreteList);
      let interpreteItems: Interprete[] = [...interpreteList];
      // if (filterWorkCollection !== "") {
      //   workItems = workItems.filter(
      //     (workItem: Work) => workItem.workCollection === filterWorkCollection
      //   );
      // }
      setInterpretations(interpreteItems);
    });
  }, []);

  const afterDelete = (interpreteItem: Work) => {
    const ind: number = interpretations.findIndex(
      (interprete: Interprete) => interprete.id === interpreteItem.id
    );
    if (ind >= 0) {
      const interpretationsUpd: Interprete[] = [...interpretations];
      interpretationsUpd.splice(ind, 1);
      setInterpretations(interpretationsUpd);
    }
  };

  const items: JSX.Element[] = interpretations.map(
    (interprete: Interprete, i) => {
      return (
        <InterpreteItem
          index={i}
          key={interprete.id}
          interprete={interprete}
          afterDelete={afterDelete}
        />
      );
    }
  );

  const workCollectionSelect = (workCollectionId: string) => {
    console.log("workCollectionSelect - workCollectionId", workCollectionId);
    // setFilterWorkCollection(workCollectionId);
  };

  return (
    <Div>
      <DivFilter>
        <WorkCollectionSelect
          name="filterWorkCollection"
          control={control}
          label="Work Collection"
          onSelect={workCollectionSelect}
          includeEmpty={true}
        />
      </DivFilter>
      {/* <HomeLink to="/work/new">Add Work Collection</HomeLink> */}
      <DivList>{items}</DivList>
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  margin-left: 5%;
  margin-top: 2rem;
  width: 90%;
  flex-direction: column;
  color: #764141;
`;

const DivFilter = styled.div`
  width: 25%;
  margin-bottom: 2rem;
  div {
    width: 100%;
  }
`;

const ButtonAdd = styled(Button)`
  margin-top: 2rem;
  width: 10%;
`;

const DivList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 6rem;
  row-gap: 2rem;
  margin-top: 2rem;
`;

export default InterpreteList;
