import { SelectFieldOption } from "components/UI/SelectField";
import { WorkCollection } from "type/work_collection";

export function workCollectionDefaultGet(): WorkCollection {
  return {
    id: null,
    name: "",
  };
}

export function workCollectionOptionsGet(
  workCollections: WorkCollection[],
  includeEmpty: boolean = false,
  emptyLabel: string = ""
): SelectFieldOption[] {
  let options: SelectFieldOption[] = workCollections.map(
    (workCollection: WorkCollection) => ({
      value: workCollection.id + "",
      label: workCollection.name,
    })
  );
  if (includeEmpty) {
    options = [{ value: "", label: emptyLabel }, ...options];
  }
  return options;
}

export async function workCollectionByNameGet(
  name: string
): Promise<WorkCollection | null> {
  let result: WorkCollection | null = null;

  return result;
}

export function workCollectionNameGet(
  workCollections: WorkCollection[],
  workCollectionId: string
): string {
  let name = "";
  const workCollection: WorkCollection | undefined = workCollections.find(
    (item) => item.id === workCollectionId
  );
  if (workCollection) {
    name = workCollection.name;
  }
  return name;
}

export function workCollectionByNameFromStateGet(
  workCollections: WorkCollection[],
  workCollectionName: string
): WorkCollection | undefined {
  let workCollection: WorkCollection | undefined = workCollections.find(
    (item) => item.name === workCollectionName
  );
  return workCollection;
}
