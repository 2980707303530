import React from "react";
import { Control, FieldValues } from "react-hook-form";
import SelectField, { SelectFieldOption } from "components/UI/SelectField";
import { useRecoilValue } from "recoil";
import { workCollectionsState } from "store/atoms/work_collections";
import { workCollectionOptionsGet } from "util/work_collection_common";
import { interpreteLangOptionsGet } from "util/interprete_lang";

type Props = {
  name: string;
  control: Control<FieldValues, any>;
  label: string;
  defaultValue?: string | number | null;
  onSelect?: Function;
};

const InterpreteLangSelect = ({
  name,
  control,
  label,
  defaultValue,
  onSelect,
}: Props) => {
  const workCollections = useRecoilValue(workCollectionsState);

  const langOptions: SelectFieldOption[] = interpreteLangOptionsGet();

  return (
    <SelectField
      name={name}
      control={control}
      label={label}
      options={langOptions}
      defaultValue={defaultValue}
      onSelect={onSelect}
    />
  );
};

export default InterpreteLangSelect;
