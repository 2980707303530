import { shuffleArray } from "common/rand";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { worksGet } from "service/work/work_common";
import { workActiveState } from "store/atoms/work_active";
import { workCollectionActiveState } from "store/atoms/work_collection_active";
import styled from "styled-components";
import { Work } from "type/work";
import { workDefaultGet } from "util/work_common";

type Props = {
  // setWorkInterprete: Function;
};

const WorkImage = ({}: Props) => {
  const workCollectionActive = useRecoilValue(workCollectionActiveState);
  const setWorkActive = useSetRecoilState(workActiveState);
  const [works, setWorks] = useState<Work[]>([]);
  const [work, setWork] = useState<Work>(workDefaultGet());
  useEffect(() => {
    worksGet({ workCollection: workCollectionActive.id }).then(
      (workList: Work[]) => {
        if (workList.length > 0) {
          workList = shuffleArray(workList);
          setWorks(workList);
        }
      }
    );
  }, [workCollectionActive]);
  useEffect(() => {
    if (works.length > 0) {
      setWork(works[0]);
      setWorkActive(works[0]);
    }
  }, [works]);
  const navigate = useNavigate();
  const [workInterprete, setWorkInterprete] = useState<Work>(workDefaultGet());
  const workActive = useRecoilValue(workActiveState);

  const workNavigate = (navType: string) => {
    let indexCurrent: number = works.findIndex((item) => item.id === work.id);
    let n: number = works.length;
    let index: number = indexCurrent;
    if (navType === "previous") {
      index = index > 0 ? index - 1 : n - 1;
    } else if (navType === "next") {
      index = index <= n - 2 ? index + 1 : 0;
    }
    setWork(works[index]);
    setWorkInterprete(works[index]);
    console.log("workNavigate", navType);
  };

  const interpretationNewNavigate = () => {
    console.log("interpretationNewNavigate");
    const workId: string | null = workInterprete.id
      ? workInterprete.id
      : workActive.id;
    if (workId) {
      navigate(`/work-interprete/new?work=${workId}`);
    }
  };

  return (
    <Div>
      <DivStart onClick={interpretationNewNavigate}>START</DivStart>
      <Info>
        <div>
          <i>{work.name}</i>, {work.author}
        </div>
        {/* <DivWork>{work.name}</DivWork>
        <DivAuthor>{work.author}</DivAuthor> */}
      </Info>
      <Nav>
        <NavItem onClick={() => workNavigate("previous")}>prethodna</NavItem>
        <NavItem onClick={() => workNavigate("next")}>naredna</NavItem>
      </Nav>
      <DivImg>
        <Img src={work.imagePath} />
      </DivImg>
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 86%;
`;

const Nav = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  font-size: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;

  div {
    margin-left: 3rem;
  }
`;

const DivAuthor = styled.div`
  font-size: 2rem;
`;

const DivWork = styled.div`
  font-size: 2rem;
  font-style: italic;
`;

const DivStart = styled.div`
  margin-top: 1rem;
  text-align: right;
  font-size: 2.5rem;
  cursor: pointer;
  color: #764141;
`;

const NavItem = styled.div`
  cursor: pointer;
  font-size: 1.5rem;
`;

const DivImg = styled.div`
  height: 70%;
  text-align: right;
  display: flex;
  justify-content: right;
`;

const Img = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  font-size: 1.5rem;
  margin-top: 1rem;
  justify-content: flex-end;
  text-align: right;
  div {
    text-align: right;
    // margin-left: 3rem;
  }
`;

export default WorkImage;
