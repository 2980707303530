import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import styled from "styled-components";

type Props = {
  guideOpen: boolean;
  setGuideOpen: Function;
};

const Guide = ({ guideOpen, setGuideOpen }: Props) => {
  const handleClose = () => {
    setGuideOpen(false);
  };

  return (
    <Div>
      <Dialog open={guideOpen} onClose={handleClose}>
        <DialogTitle>Uputstvo</DialogTitle>
        <DialogContent>
          <DialogContentText fontSize="1.2rem">
            Preko ove aplikacije može se praviti interpretacija odabrane slike
            tako što se putem mikrofona navode opisi. Na osnovu opisa se
            slučajnim putem preuzimaju fotografije iz baze fotografija slobodnih
            za korišćenje. Ove fotografije se takođe na slučajan način
            postavljaju na ekran, dodeljuje im se različit stepen providnosti.
            Na taj način se formira nova slika koja predstavlja interpretaciju
            nastalu kombinovanjem opisa i slučajnog izbora fotografija na osnovu
            tih opisa. Ovde je moguće odabrati i jezik na kome ćemo unositi
            opise - srpski ili engleski.
          </DialogContentText>
          <DialogContentText fontSize="1.2rem" marginTop="1rem">
            Odabir slike za interpretaciju se vrši tako što se listaju dostupne
            slike. Nakon što se odlučimo za jednu od slika klikne se na START i
            nakon toga odlazi na stranu interpretacije. Na strani za
            interpretaciju pokrećemo prepoznavanje opisa tako što kliknemo na
            START. Zatim putem mikrofona unosimo opise na osnovu kojih se
            dobijaju fotografije i postavljaju na ekran.
          </DialogContentText>
          <DialogContentText fontSize="1.2rem" marginTop="1rem">
            Nakon što završimo unos, kliknemo na opciju ZAVRŠI nakon čega nam se
            otvara dijalog za unos opcionih polja interpretacije. Klik na OK nas
            vraća na početnu stranu za odabir nove slike.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Div>
  );
};

const Div = styled.div`
  font-size: 2rem;
`;

export default Guide;
