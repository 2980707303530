import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import WorkInterpretationItem from "./WorkInterpretationItem";

const WorkInterpretations = () => {
  const items: JSX.Element[] = [];
  for (let i = 0; i < 10; i++) {
    items.push(<WorkInterpretationItem index={i} />);
  }
  return (
    <Div>
      <HomeLink to="/">New Interpretation</HomeLink>
      <DivList>{items}</DivList>
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  margin-left: 5%;
  margin-top: 2rem;
  width: 90%;
  flex-direction: column;
  color: #764141;
`;

const HomeLink = styled(Link)`
  margin-top: 2rem;
  font-size: 1.5rem;
  cursor: pointer;
  color: #764141;
  text-decoration: none;
`;

const DivList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 2rem;
  row-gap: 2rem;
  margin-top: 2rem;
`;

export default WorkInterpretations;
