import axios from "axios";
import { apiUrlGet } from "common/common";
import { WorkCollection } from "type/work_collection";
import { workCollectionDefaultGet } from "util/work_collection_common";

export async function workCollectionAdd(name: string): Promise<string> {
  let workCollectionId: string = "";
  const workCollectionUrl = apiUrlGet("work-collection");
  const response = await axios.post(workCollectionUrl, {
    name,
  });
  workCollectionId = response.data;

  return workCollectionId;
}

export async function workCollectionsGet(
  options: Object = {}
): Promise<WorkCollection[]> {
  let workCollections: WorkCollection[] = [];
  let queryPart = "";
  if ("name" in options) {
    queryPart += (queryPart !== "" ? "&" : "") + "name=" + options.name;
  }
  if (queryPart) {
    queryPart = "?" + queryPart;
  }
  const workCollectionUrl = apiUrlGet("work-collection" + queryPart);
  const response = await axios.get(workCollectionUrl);
  workCollections = response.data.map((workCollectionItem: WorkCollection) => {
    const workCollection: WorkCollection = workCollectionDefaultGet();
    workCollection.id = workCollectionItem.id;
    workCollection.name = workCollectionItem.name;
    return workCollection;
  });

  return workCollections;
}

export async function workCollectionGet(
  workCollectionId: string
): Promise<WorkCollection> {
  let workCollection: WorkCollection = workCollectionDefaultGet();
  const workUrl = apiUrlGet(`work/${workCollectionId}`);
  const response = await axios.get(workUrl);
  const workCollectionItem: WorkCollection = response.data;
  workCollection.id = workCollectionItem.id;
  workCollection.name = workCollectionItem.name;

  return workCollection;
}

export async function workCollectionUpdate(
  id: string,
  name: string
): Promise<string> {
  const workCollectionUrl = apiUrlGet(`work-collection/${id}`);
  await axios.put(workCollectionUrl, {
    name,
  });

  return id;
}

export async function workCollectionDelete(id: string): Promise<string> {
  const workCollectionUrl = apiUrlGet(`work-collection/${id}`);
  await axios.delete(workCollectionUrl);

  return id;
}
