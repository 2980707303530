/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import TitleLanguage from "./TitleLanguage";
import WorkNavigation from "./WorkNavigation";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { workCollectionActiveState } from "store/atoms/work_collection_active";
import { workCollectionsState } from "store/atoms/work_collections";
import {
  workCollectionByNameFromStateGet,
  workCollectionByNameGet,
} from "util/work_collection_common";

const NasocirStart = () => {
  const setWorkCollectionActive = useSetRecoilState(workCollectionActiveState);
  const workCollections = useRecoilValue(workCollectionsState);
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const workCollectionName = searchParams.get("workCollection");
    if (workCollectionName) {
      const workCollection = workCollectionByNameFromStateGet(
        workCollections,
        workCollectionName
      );
      if (workCollection) {
        setWorkCollectionActive(workCollection);
      }
    }
  }, []);

  return (
    <Div>
      <TitleLanguage />
      <WorkNavigation />
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

export default NasocirStart;
