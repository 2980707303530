import { Button } from "@mui/material";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useRecoilValue } from "recoil";
import { workDelete } from "service/work/work_common";
import { workCollectionsState } from "store/atoms/work_collections";
import styled from "styled-components";
import { Work } from "type/work";
import { workCollectionNameGet } from "util/work_collection_common";
import WorkItemEdit from "./WorkItemEdit";

type Props = {
  index: number;
  work: Work;
  afterDelete?: Function;
};

const WorkItem = ({ index, work, afterDelete }: Props) => {
  const workCollections = useRecoilValue(workCollectionsState);
  const [editWork, setEditWork] = useState<boolean>(false);
  const [workItem, setWorkItem] = useState<Work>(work);
  const rowStart: number = Math.trunc(index / 5) + 1;
  const rowEnd: number = rowStart + 1;
  const handleEditClick = () => {
    setEditWork(true);
  };
  const handleDeleteClick = async () => {
    if (workItem.id) {
      await workDelete(workItem.id);
      toast.success("Work deleted");
      if (afterDelete) {
        afterDelete(workItem);
      }
    }
  };
  const afterUpdate = (workData: Work) => {
    console.log("afterUpdate - workData", workData);
    setWorkItem(workData);
    setEditWork(false);
  };

  return (
    <Div
      style={{
        gridArea: `${rowStart} / ${(index % 5) + 1} / ${rowEnd} / ${
          (index % 5) + 2
        }`,
      }}
    >
      {!editWork && (
        <div>
          {workItem.author} - {workItem.name}
        </div>
      )}
      {!editWork && workItem.imagePath && <Img src={workItem.imagePath} />}

      {editWork && (
        <WorkItemEdit
          work={workItem}
          onCancelClick={() => setEditWork(false)}
          afterUpdate={afterUpdate}
        />
      )}

      {!editWork && (
        <div>
          <div>
            {workCollectionNameGet(workCollections, work.workCollection)}
          </div>
          <div>Info work count</div>
        </div>
      )}
      <DivButtons>
        {!editWork && (
          <Button onClick={handleEditClick} variant="outlined">
            Edit
          </Button>
        )}
        <Button onClick={handleDeleteClick} variant="outlined">
          Delete
        </Button>
      </DivButtons>
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  cursor: pointer;
  color: #764141;
  text-decoration: none;
`;

const DivButtons = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-direction: row;
  justify-content: space-between;
`;

const Img = styled.img`
  width: 100%;
`;

export default WorkItem;
