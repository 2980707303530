import { ImageDescription } from "type/interprete";
import { imageDescriptionDefaultGet } from "util/interprete_common";
import axios from "axios";
import { randInRangeGet, randFloat01InRangeGet } from "common/rand";
import { apiUrlGet } from "common/common";

export async function imageDescriptionSearch(
  searchText: string,
  lang: string = "sr"
): Promise<ImageDescription> {
  const imageDescription: ImageDescription =
    imageDescriptionDefaultGet(searchText);
  const imagePaths: string[] = await imSearchGet(searchText, lang);
  const n: number = imagePaths.length;
  const posN = imPosDimGet(n);
  for (let i = 0; i < n; i++) {
    imageDescription.images.push({
      left: posN[i].l,
      top: posN[i].t,
      width: posN[i].w,
      path: imagePaths[i],
    });
  }

  return imageDescription;
}

async function imSearchPathGet(
  searchText: string,
  n: number,
  lang = "sr"
): Promise<string[]> {
  const imPN = await imCSGet(searchText, n, lang);
  // const imPN = imTestPathGet(n);
  return imPN;
}

async function imCSGet(
  searchText: string,
  n: number,
  lang = "sr"
): Promise<string[]> {
  let imPN: Array<string> = [];
  if (searchText !== "") {
    searchText = encodeURIComponent(searchText);
    if (lang === "sr") {
      const translateUrl = apiUrlGet("interprete/translate");
      searchText = await axios.post(translateUrl, { text: searchText });
    }
    const API_KEY = "AIzaSyAliVaSsNdSzgNg5-A6URREXLoJbm7lIIY";
    let url = `https://www.googleapis.com/customsearch/v1?key=${API_KEY}&cx=b8f642a06d90be722&q=${searchText}&searchType=image&num=${n}`;
    try {
      const imageData = await axios.get(url);
      if (
        imageData.hasOwnProperty("data") &&
        imageData["data"].hasOwnProperty("items")
      ) {
        const imageDataItems = imageData.data.items;
        imPN = imageDataItems.map((el: any) => {
          return el.link;
        });
      }
    } catch (error) {
      // console.log(error);
    }
  }
  return imPN;
}

function imPosDimGet(n: number) {
  const posN = [];
  const lMin = 0,
    lMax = 1,
    tMin = 0,
    tMax = 1,
    wMin = 0.05,
    wMax = 0.4;
  for (let i = 0; i < n; i++) {
    const l = randFloat01InRangeGet(lMin, lMax);
    const t = randFloat01InRangeGet(tMin, tMax);
    const w = randFloat01InRangeGet(wMin, wMax);
    posN.push({ l, t, w });
  }
  return posN;
}

async function imSearchGet(
  searchText: string,
  lang: string = "sr"
): Promise<string[]> {
  let n = randInRangeGet(3, 5);
  const imPN = await imSearchPathGet(searchText, n, lang);
  return imPN;
}
