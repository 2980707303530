import { atom } from "recoil";
import { WorkCollection } from "type/work_collection";
import { workCollectionDefaultGet } from "util/work_collection_common";

const defaultValue: WorkCollection = workCollectionDefaultGet();

export const workCollectionActiveState = atom({
  key: "workCollectionActive",
  default: defaultValue,
});
