import React, { useState } from "react";
import { RecoilRoot } from "recoil";
import "./App.css";
import { DBConfig } from "../indexeddb/DBConfig";
import { initDB } from "react-indexed-db";
import { envTypeGet, apiUrlBaseGet } from "../common/common";
import NasocirMainContent from "components/layout/NasocirMainContent";

// if (typeof process === "undefined" || process?.env?.NODE_ENV !== "test") {
//   initDB(DBConfig);
// }
// initDB(DBConfig);

console.log(
  "envTypeGet: ",
  envTypeGet(),
  "apiUrlBaseGet: ",
  apiUrlBaseGet(false)
);

fetch(apiUrlBaseGet(false) + "/api")
  .then((response) => {
    return response.text();
  })
  .then((result) => {
    console.log("result", result);
  });

function App() {
  const [diktafontNavigationAct, setDiktafontNavigationAct] = useState(false);
  const diktafontNavigationActToggle = () => {
    setDiktafontNavigationAct((currentValue) => !currentValue);
  };
  return (
    <RecoilRoot>
      <NasocirMainContent />
    </RecoilRoot>
  );
}

export default App;
