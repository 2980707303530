import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { worksGet } from "service/work/work_common";
import { workCollectionsGet } from "service/work_collection/work_collection_common";
import { workCollectionsState } from "store/atoms/work_collections";
import styled from "styled-components";
import { WorkCollection } from "type/work_collection";
import { workCollectionDefaultGet } from "util/work_collection_common";
import WorkCollectionItem from "./WorkCollectionItem";
import WorkCollectionItemEdit from "./WorkCollectionItemEdit";

const WorkCollectionList = () => {
  const [workCollections, setWorkCollections] =
    useRecoilState(workCollectionsState);

  // const [workCollections, setWorkCollections] = useState<WorkCollection[]>([]);
  const [addWorkCollection, setAddWorkCollection] = useState<boolean>(false);
  useEffect(() => {
    workCollectionsGet().then((workCollectionList: WorkCollection[]) => {
      console.log(workCollectionList);
      setWorkCollections(workCollectionList);
    });
  }, []);

  const afterDelete = (workCollectionItem: WorkCollection) => {
    const ind: number = workCollections.findIndex(
      (workCollection: WorkCollection) =>
        workCollection.id === workCollectionItem.id
    );
    if (ind >= 0) {
      const workCollectionsUpd: WorkCollection[] = [...workCollections];
      workCollectionsUpd.splice(ind, 1);
      setWorkCollections(workCollectionsUpd);
    }
  };

  const items: JSX.Element[] = workCollections.map(
    (workCollection: WorkCollection, i) => {
      return (
        <WorkCollectionItem
          index={i}
          key={workCollection.id}
          workCollection={workCollection}
          afterDelete={afterDelete}
        />
      );
    }
  );

  const addClickHandle = () => {
    console.log("addClickHandle");
    setAddWorkCollection(true);
  };

  const afterAdd = (workCollection: WorkCollection) => {
    setWorkCollections([...workCollections, workCollection]);
    setAddWorkCollection(false);
  };

  return (
    <Div>
      {!addWorkCollection && (
        <ButtonAdd variant="outlined" color="inherit" onClick={addClickHandle}>
          Add Work Collection
        </ButtonAdd>
      )}
      {addWorkCollection && (
        <WorkCollectionItemEdit
          workCollection={workCollectionDefaultGet()}
          onCancelClick={() => setAddWorkCollection(false)}
          afterAdd={afterAdd}
        />
      )}
      {/* <HomeLink to="/work/new">Add Work Collection</HomeLink> */}
      <DivList>{items}</DivList>
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  margin-left: 5%;
  margin-top: 2rem;
  width: 90%;
  flex-direction: column;
  color: #764141;
`;

const ButtonAdd = styled(Button)`
  margin-top: 2rem;
  width: 15%;
  // font-size: 1.5rem;
  // cursor: pointer;
  // color: #764141;
  // text-decoration: none;
`;

const DivList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 6rem;
  row-gap: 2rem;
  margin-top: 2rem;
`;

export default WorkCollectionList;
