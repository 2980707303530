import { Button, Paper, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputField from "components/UI/InputField";
import { workAdd, workGet } from "service/work/work_common";
import toast from "react-hot-toast";
import { WorkEditSchema } from "validation/schema/work_edit.schema";
import { Work } from "type/work";
import { workDefaultGet } from "util/work_common";
import { WorkCollection } from "type/work_collection";
import { workCollectionDefaultGet } from "util/work_collection_common";
import {
  workCollectionAdd,
  workCollectionUpdate,
} from "service/work_collection/work_collection_common";

type Props = {
  workCollection: WorkCollection;
  onCancelClick?: Function;
  afterAdd?: Function;
  afterUpdate?: Function;
};

const WorkCollectionItemEdit = ({
  workCollection,
  onCancelClick,
  afterAdd,
  afterUpdate,
}: Props) => {
  const { handleSubmit, reset, control, setValue, getValues } = useForm({});

  const onSubmit = async (data: any) => {
    console.log(data);
    if (!workCollection.id) {
      const workCollectionId: string = await workCollectionAdd(data.name);
      console.log("added", workCollectionId);
      toast.success("Work Collection added");
      if (afterAdd) {
        afterAdd({
          name: data.name,
          id: workCollectionId,
        });
      }
    } else {
      console.log("update");
      await workCollectionUpdate(workCollection.id, data.name);
      toast.success("Work Collection updated");
      if (afterUpdate) {
        afterUpdate({
          name: data.name,
          id: workCollection.id,
        });
      }
    }
  };

  const handleCancelClick = () => {
    if (onCancelClick) {
      onCancelClick();
    }
  };

  return (
    <Div>
      <DivWorkCollection>
        <Form>
          <InputField
            name="name"
            control={control}
            label="Name"
            defaultValue={workCollection.name}
          />
          <DivButtons>
            <Button onClick={handleSubmit(onSubmit)} variant="outlined">
              Submit
            </Button>
            <Button onClick={handleCancelClick} variant="outlined">
              Cancel
            </Button>
          </DivButtons>
        </Form>
      </DivWorkCollection>
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  margin-left: 0%;
  margin-top: 0rem;
  width: 100%;
  flex-direction: row;
  color: #764141;
`;

const Form = styled.form`
  display: flex;
  margin-left: 0%;
  margin-top: 0rem;
  width: 100%;
  flex-direction: column;
  color: #764141;
`;

const DivWorkCollection = styled.div`
  margin-top: 0rem;
`;

const DivButtons = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-direction: row;
  justify-content: space-between;
`;

export default WorkCollectionItemEdit;
