import React from "react";
import { useRecoilValue } from "recoil";
import { interpreteActiveState } from "store/atoms/interprete_active";
import styled from "styled-components";
import { ImageDescription, ImagePos } from "type/interprete";
import { imOpacityGet } from "util/image_common";
import { interpreteImagePosGet } from "util/interprete_common";

const InterpreteImages = () => {
  const interpreteActive = useRecoilValue(interpreteActiveState);

  const interpreteImagePos: ImagePos[] = interpreteImagePosGet(
    interpreteActive.interprete
  );
  const n: number = interpreteImagePos.length;
  const imagePositions = interpreteImagePos.map(
    (imagePos: ImagePos, index: number) => {
      const path: string = imagePos.path;
      const top: string = imagePos.top * 100 + "%";
      const left: string = imagePos.left * 100 + "%";
      const width: string = imagePos.width * 100 + "%";
      const opacity: number = imOpacityGet(index, n);

      return (
        <Img
          src={path}
          key={`image_pos_${index}`}
          style={{ top, width, left, opacity }}
        />
      );
    }
  );
  return <Div>{imagePositions}</Div>;
};

const Div = styled.div`
  display: flex;
  margin-left: 5%;
  margin-top: 2rem;
  width: 20%;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  color: #764141;
`;

const Img = styled.img`
  position: absolute;
`;

export default InterpreteImages;
